import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { Truncate } from 'ui-base/truncate/Truncate'

const typographyStyle = css`
  color: var(--wpp-grey-color-800);
`

export const CollectionCard = styled(WppCard)`
  &::part(card) {
    display: flex;
    flex-direction: column;
    min-height: 146px;
    overflow: visible;
  }

  &::part(header-wrapper) {
    height: 28px;
    margin-bottom: 24px;
  }
`

export const CollectionName = styled(Truncate)`
  color: var(--wpp-grey-color-1000);
  overflow: hidden;

  &::part(typography) {
    display: block;
  }
`

export const CollectionCardFooter = styled('footer')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`

export const Timestamp = styled(WppTypography)`
  flex-shrink: 0;
  ${typographyStyle};
`
