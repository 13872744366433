import { TFunction } from 'i18next'
import * as Yup from 'yup'

import {
  CollectionDTO,
  CollectionProductDTO,
  CollectionProductWithVersionsDTO,
  CollectionsShortVersionDTO,
} from 'types/collections/collection'
import { MayBeNull } from 'types/common/utils'

export enum CreateCollectionErrorCode {
  DUPLICATE_NAME_ON_SAME_TENANT = 'DEVHUB_COLLECTION:DUPLICATE_COLLECTION_NAME_ON_SAME_WORKSPACE',
  DUPLICATE_NAME_ON_OTHER_TENANTS = 'DEVHUB_COLLECTION:DUPLICATE_COLLECTION_NAME_ON_OTHER_WORKSPACES',
}

export type CollectionFormShortVersionDTO = CollectionsShortVersionDTO & {
  isSelected: boolean
}

export type CollectionFormProductWithVersionsDTO = Omit<CollectionProductWithVersionsDTO, 'versions'> & {
  hasSelectedVersions: boolean
  versions: CollectionFormShortVersionDTO[]
}

export type CreateCollectionFormDTO = Pick<CollectionDTO, 'name'> & {
  shortDescription: MayBeNull<string>
  elements: {
    productId: string
    versionId: string
  }[]
}

export type CreateCollectionFormElementValue = Pick<
  CollectionProductDTO,
  'productId' | 'productName' | 'productType' | 'logoUrl' | 'shortDescription' | 'versionId' | 'versionName'
> & {
  isSelected: boolean
}

export type CreateCollectionFormValues = Pick<CollectionDTO, 'name' | 'shortDescription'> & {
  elements: CreateCollectionFormElementValue[]
}

export const getCreateCollectionInitialValues = (): CreateCollectionFormValues => {
  return {
    name: '',
    shortDescription: '',
    elements: [],
  }
}

export const mapCreateCollectionValues = (values: CreateCollectionFormValues): CreateCollectionFormDTO => {
  const { name, shortDescription, elements } = values

  const mappedElements = elements.map(({ productId, versionId }) => ({
    productId,
    versionId,
  }))

  return {
    name,
    shortDescription: shortDescription || null,
    elements: mappedElements,
  }
}

const NAME_FIELD_MAX_CHARS = 256
export const SHORT_DESCRIPTION_MAX_CHARS = 400

export const getCreateCollectionFormValidationSchema = (t: TFunction<['errors']>) =>
  Yup.object({
    name: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        NAME_FIELD_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: NAME_FIELD_MAX_CHARS,
        }),
      ),
    shortDescription: Yup.string()
      .trim()
      .max(
        SHORT_DESCRIPTION_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: SHORT_DESCRIPTION_MAX_CHARS,
        }),
      ),
    elements: Yup.array().required(t('errors|form_validation.required')).min(1, t('errors|form_validation.required')),
  })
